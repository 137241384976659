import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';
import { Link } from 'gatsby';

const HakaToursGiftVoucher = () => (
  <Layout page="gift-voucher">

    <Helmet
      title="Haka Tours Gift Voucher"
      meta={[
        {
          name: 'description', content: 'Congratulations, you’ve been given a gift voucher to put towards one of our award-winning Haka Tours.',
        },
        { name: 'og:title', content: 'Haka Tours Gift Voucher' },
        {
          name: 'og:description', content: 'Congratulations, you’ve been given a gift voucher to put towards one of our award-winning Haka Tours.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/gift-voucher/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/HakaToursGiftVoucherTongariroCrossing.jpg?fit=crop&auto=format" />
    <section className="l-section">
      <div className="l-container">
        <h1 className="l-contact-us__title c-heading c-heading--h1 u-text--center">
          The Gift of Travel
        </h1>
        <br />
        <h3 className="congrate-box">
          Congratulations, you’ve been given a gift voucher to put towards one of our award-winning Haka Tours.
          With a choice of adventure, snow, and Haka Plus, and the best guides in the business, we guarantee an unforgettable journey of Aotearoa New Zealand.
          Whether you have 3 days or 20 days to spare, you can expect stunning scenery, culture and adventure in equal measure.
        </h3>
        <br />
        <p>
          Haka Tours are the perfect way to experience Aotearoa New Zealand - whether you’re travelling with your best mate or having a solo adventure.
          Simply pick your Haka Tour (that’s the hardest bit!), when you want to travel and leave the rest to us!
        </p>
        <p>
          Plus we understand that travel right now is a little unpredictable, so you can secure your tour with just a $99 deposit and 100% travel credit applies if you cannot make your tour.
          Check out our Travel with Confidence page for further information.
        </p>
        <p>
          Your Haka Tours Gift Voucher is valid for bookings from 18 October - 31 December 2021 and valid for travel 01 November 2021 - 30 March 2022.
        </p>
        <p>
          Check out <a href="https://hakatours.com/tours/" target="_blank" rel="noopener noreferrer">our tours page</a> to see which tour takes your fancy!
          If you have any questions, don’t hesitate to reach out to us at <a href="mailto: info@hakatours.com">info@hakatours.com</a> or +64 9 520 5593.
        </p>
        <br />
        <div className="center">
          <Link
            to="tours"
            className="c-button c-button--primary c-button--wide"
          >
            PICK YOUR TOUR!
          </Link>
        </div>
        <br />
        <section className="l-section condition-section">
          <div className="l-container condition-header">
            Terms & Conditions
          </div>
          <div className="l-container condition-text">
            This offer is valid for bookings made between 18 October - 31 December 2021 and valid for travel from 01 November 2021 - 30 October 2022.
            Promotion is subject to availability and blackout periods may apply.
            Accommodation upgrades available upon request but subject to availability.
            To be redeemed in conjunction with Haka Tours and Tourism New Zealand only.
            The deal applies to available rates at time of booking.
            To book your tour, please visit <a href="https://hakatours.com/" target="_blank" rel="noopener noreferrer">hakatours.com</a> and enter promocode ‘TNZGift’ at the checkout.
            A deposit of $99 is required to secure your booking.  Offer not combinable with any other promotions or discounts.
            All our usual T&C’s apply. For any questions, do not hesitate to reach us at <a href="mailto: info@hakatours.com">info@hakatours.com</a> or +64 9 520 5593.
          </div>
        </section>
      </div>
    </section>
  </Layout>
);

export default HakaToursGiftVoucher;
